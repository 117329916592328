<template>
  <div class="record">
    <div class="header">
      <mi-title :title="title">
        <span class="amount">{{ $tc('record.amount', balance) }}</span>
      </mi-title>
    </div>
    <div class="content" v-show="datas.length">
      <div class="list">
        <div
          class="month"
          v-for="{ month, totalFee, list } in datas"
          :key="month"
        >
          <h2 class="total">{{ month }} {{ $tc('common.total', totalFee) }}</h2>
          <ul class="list-month">
            <li
              v-for="{
                chargeOrderId,
                chargeTypeDesc,
                chargeFee,
                chargeTime,
                orderDesc,
                orderFee,
                tradeId
              } in list"
              :key="type === 'recharge' ? chargeOrderId : tradeId"
            >
              <p class="type">
                {{ type === 'recharge' ? chargeTypeDesc : orderDesc }}
                <span class="add" v-if="type === 'recharge'">{{
                  chargeFee
                }}</span>
                <span class="increment" v-else>{{ orderFee }}</span>
              </p>
              <p class="time">
                {{ chargeTime }}
                <span class="unit">
                  {{ $t('common.mibi') }}
                </span>
              </p>
            </li>
          </ul>
          <p class="line"></p>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <em slot="no-more">{{ $t('record.footer') }}</em>
    </infinite-loading>
    <div v-if="!datas.length" class="not-data">
      {{ $t('common.notData') }}
    </div>
  </div>
</template>

<script>
import { checkPrivacy } from '@/mixins'
import { MiTitle } from '@/components'
import dayjs from 'dayjs'
import { unitFormat } from '@/utils'

export default {
  mixins: [checkPrivacy],
  components: {
    MiTitle
  },
  data() {
    const { type } = this.$route.params
    const api = type === 'recharge' ? 'getRecharge' : 'getTrade'

    return {
      type,
      api,
      balance: '',
      datas: [],
      pageInfo: {
        pageNo: 0,
        pageSize: 10,
        totalPage: 1
      },
      busy: false
    }
  },
  computed: {
    title() {
      const { type } = this

      return type === 'recharge'
        ? this.$t('record.rechargeTitle')
        : this.$t('record.customeTitle')
    }
  },
  created() {
    this.checkPrivacy()
  },
  methods: {
    async infiniteHandler($state) {
      const {
        api,
        type,
        datas,
        pageInfo: { pageNo, pageSize, totalPage }
      } = this

      if (pageNo >= totalPage) {
        $state.complete()
        return
      }

      const res = await this.$http[api]({
        pageSize,
        pageNo: (this.pageInfo.pageNo += 1)
      })
      const len = datas.length
      const { balance, total, rows } = res
      const nextStart = rows[0]
      const preEnd = datas[len - 1] || {}
      this.balance = balance !== undefined ? unitFormat(balance) : ''
      if (rows.length) {
        rows.forEach(item => {
          item.totalFee = unitFormat(item.totalFee)
          item.list.forEach(record => {
            const { chargeTime, chargeFee, orderFee, payTime } = record

            record.chargeTime = dayjs(chargeTime * 1000).format(
              'YYYY-MM-DD HH:mm'
            )

            if (type === 'recharge') {
              let val = unitFormat(chargeFee)
              record.chargeFee = val > 0 ? `+${val}` : val
              record.chargeTime = dayjs(chargeTime * 1000).format(
                'YYYY-MM-DD HH:mm'
              )
            } else {
              let val = unitFormat(orderFee)
              record.orderFee = val > 0 ? `-${val}` : `+${Math.abs(val)}`
              record.chargeTime = dayjs(payTime * 1000).format(
                'YYYY-MM-DD HH:mm'
              )
            }
          })
        })

        if (nextStart.month === preEnd.month) {
          datas[datas.length - 1].list.push(...nextStart.list)
          rows.shift()
        }
        this.datas = [...datas, ...rows]
        this.pageInfo.totalPage = Math.ceil(total / pageSize)
        $state.loaded()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.record {
  min-height: 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header {
    padding-bottom: 40px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    .amount {
      font-size: 38px;
      color: $color-text-secondary;
      flex-shrink: 0;
    }
  }
  .content {
    flex: 1;
    .list {
      padding: 50px 80px 90px;
      .month {
        .total {
          font-size: 36px;
          color: #8c93b0;
        }
        .list-month {
          margin-top: 70px;
          li {
            margin-top: 90px;
            &:first-child {
              margin-top: 0;
            }
            p {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .type {
              font-weight: 600;
              font-size: 50px;
              color: $theme-text-color;
              span {
                font-size: 56px;
                font-family: Mitype;
              }
              .add {
                color: $theme-color;
              }
              .increment {
                color: $increment-color;
              }
            }
            .time {
              font-size: 36px;
              color: $color-text-secondary;
            }
          }
        }
        .line {
          height: 2px;
          background-color: $color-split-line;
          margin: 106px 0 86px;
        }
        &:last-child {
          .line {
            display: none;
          }
        }
      }
    }
    .footer {
      text-align: center;
      color: #8c93b0;
      font-size: 36px;
    }
  }
  .loading {
    width: 144px;
    height: 144px;
    margin: 0 auto;
  }
  .not-data {
    @extend .mi-not-data;
    @include bg-image('not');
  }
}
</style>
